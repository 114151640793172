.forget-pass {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    border: none;
    span.fp {
      margin-inline-end: 0.5rem;
      width: 1.5em !important;
      height: 1.5em !important;
      border-radius: 50% !important;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  &__input {
    width: 18.75rem !important;
  }

  .auth__input-phone {
    // background-color: red;
    width: 519px;
    input {
      height: 52px;
    }
  }
}

@include to-mobile-sm {
  .forget-pass {
    &__input-container {
      .antd-country-phone-input {
        width: 90%;
      }
    }
    &__input {
      width: 95% !important;
    }
    &__btn {
      width: 95% !important;
    }
  }
}
