/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
@font-face {
  font-family: "bahnschrift";
  src: url("../../fonts/bahnschrift/BAHNSCHRIFT\ 1.TTF");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "bahnschrift", sans-serif;
}
input,
textarea {
  font-size: 16px;
  -webkit-text-size-adjust: 100% !important;
}
html {
  scrollbar-gutter: auto;
}
ul,
li {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

#root {
  height: 100%;
  // display: flex;
  margin-top: 0 !important;
  flex-direction: column;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* -------------------------------------------------------------------------- */
/*                                   blocks                                   */
/* -------------------------------------------------------------------------- */
section.main-section {
  flex: 1;
  min-height: 46vh;
  padding-top: 89px;
}
.main-section.home-section {
  padding-top: 0;
}
.h-100vh {
  height: 100vh;
  min-height: 28.125rem;
}
.main-section {
  padding-top: 89px;
  margin-bottom: 2.4rem;
}
.main-section.subscribtion {
  margin-bottom: 0rem;
}
/* -------------------------------------------------------------------------- */
/*                                   Classes                                  */
/* -------------------------------------------------------------------------- */

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.show {
  visibility: visible;
  opacity: 1;
}
.flex-1 {
  flex: 1 !important;
}
/* -------------------------------------------------------------------------- */
/*                                  elements                                  */
/* -------------------------------------------------------------------------- */
.logo-center {
  text-align: center;
  margin-bottom: 56px !important;
}
.otp__single-container {
  input {
    width: 64px !important;
    height: 64px;
    border: 0.0625rem solid $input-border-c;
    border-radius: 4px;
    margin-inline-end: 2rem;
    @include to-mobile-sm {
      width: 45px !important;
      height: 45px;
      margin-inline-end: 1rem;
    }
  }
  &.error-message {
    input {
      border: 0.0625rem solid red !important;
    }
  }
  &:last-of-type {
    input {
      margin-inline-end: 0;
    }
  }
}

.main-header {
  h2 {
    font-size: 1.3125rem;
    font-weight: $bolder;
    color: $main-c;
  }
}

/* -------------------------------------------------------------------------- */
/*                                   placeholder                                   */
/* -------------------------------------------------------------------------- */
%video-name {
  font-size: 0.875rem;
  font-weight: bold;
  color: black;
}
%video-details {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}
/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */
.primary-color {
  color: $main-c;
}
.primary-bg {
  background-color: $main-c;
}
/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */
// from 768px to ∞
@include from-tablet-sm {
  html,
  body {
    font-size: 1rem;
  }
}
// from 0 to 767px
@include to-tablet-sm {
  html {
    // font-size: 0.8125rem;
  }
}

.font-bold-l {
  font-weight: $bolder;
}
.font-bolder {
  font-weight: $bolder;
}

/* -------------------------------------------------------------------------- */
/*                                   buttons                                  */
/* -------------------------------------------------------------------------- */
// used with antd Button
%main-button {
  border-radius: 26.44px;
  font-size: 16px;
  font-weight: $bolder;
  text-align: center !important;
}
.button-primary--watchNow {
  &:hover {
    transform: scale(1.05);
  }
}
.button-primary {
  @extend%main-button;
  background: $main-grad;
  color: white;
  border: none;
  width: 230px;
  // width: 313px;
  // width: 30%;
  height: 2.6rem;
  min-width: 13.75rem;
  &--forget-pin-page {
    width: 313px;
    @include to-mobile-sm {
      width: auto;
    }
  }
  &--watchNow {
    @include to-tablet-sm {
      width: 12.5rem;
      min-width: auto;
    }
    @include to-tablet-xs {
      width: 10rem;
      min-width: auto;
    }
    @include to-mobile-sm {
      width: 8rem;
      min-width: auto;
    }
  }
  &--add-child {
    // width: 100%;

    @include to-tablet-sm {
      width: 100%;
      min-width: auto;
    }
    // min-width: 20rem;
  }
  &--modal {
    margin: 0 auto;
    min-width: 0;
    // width: 80%;
    @include tablet-xs {
      & {
        width: 70%;
      }
    }
    @include mobile-sm {
      & {
        width: 80%;
      }
    }
    // //
    @include mobile-xxs {
      & {
        font-size: 1rem;
        // font-weight: 500;
        width: 90%;
      }
    }
  }
  &.ant-btn:hover,
  &.ant-btn:focus {
    background: $main-grad !important;
    color: white;
  }
  &.blue {
    background: #356ab3;
    &.ant-btn:hover,
    &.ant-btn:focus {
      background: #356ab3 !important;
      color: white;
    }
  }
}
.button-primary-100vw {
  @extend%main-button;
  background: $main-grad;
  color: white;
  border: none;
  width: 100%;
  height: 2.6rem;
  transition: background 0.3s ease;
  &.ant-btn:hover,
  &.ant-btn:focus {
    background: $main-grad;
    color: white;
  }
  &.blue {
    background: #356ab3;
    &.ant-btn:hover,
    &.ant-btn:focus {
      background: #356ab3 !important;
      color: white;
    }
  }
  &--joinCompetition {
    height: 4.5rem;
    font-size: 27px;
    border-radius: 999px;
    &:focus {
      background: $main-grad;
      color: white;
    }
    &:hover {
      color: white !important;
      background: linear-gradient(
        180deg,
        rgb(254, 167, 168) 0%,
        rgb(236, 49, 52) 100%
      ) !important;
    }
    &:active,
    &:visited {
      color: whitesmoke !important;
      background: $hover-grad100 !important;
    }
    @include tablet {
      font-size: 18px;
      height: 2.5rem;
    }
    @include tablet-sm {
      font-size: 18px;
      height: 2.4rem;
    }
    @include tablet-xs {
      font-size: 16px;
      height: 2rem;
    }
    @include mobile-xxs {
      font-size: 14px;
      height: 2rem;
    }
  }
}

.button-secondary {
  @extend%main-button;
  background: transparent;
  color: $main-c;
  border-width: 2px;
  border-color: $main-c;
  height: 2.6rem;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $main-c;
    border-color: $main-c;
  }
  &--addToWish {
    // width: 313px;
    width: 230px;
    position: relative;
    border: none;
    background: transparent;
    color: $main-c;
    height: 2.6rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 1500px;
      padding: 3px;
      background: linear-gradient(to bottom, #ffaaac, #ed2024);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    &:hover,
    &:active {
      // background: transparent !important;
      transform: scale(1.05);
    }
    @include to-tablet-sm {
      width: 12.5rem;
      min-width: auto;
    }
    @include to-tablet-xs {
      width: 10rem;
      min-width: auto;
    }
    @include to-mobile-sm {
      width: 8rem;
      min-width: auto;
    }

    // //
    // @include mobile-xxs {
    //   width: 6rem;
    //   min-width: auto;
    // }
  }
}

.button-secondary-mobile {
  @extend%main-button;
  background: white;
  color: $main-c;
  border-width: 2px;
  border-color: $main-c;
  height: 2.6rem;
  min-width: 13.75rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: $main-c;
    border-color: $main-c;
  }
}
.disabledButton {
  &--proceedcoupon {
    background-color: rgb(184, 184, 184) !important;
    color: #7e7e7e !important;
    border: none !important;
  }
}
.logout-btn-primary {
  width: 313px;
}
@include to-tablet-sm {
  .logout-btn-primary {
    width: 80%;
  }
}
// used with Bootstrap Button
/* -------------------------------------------------------------------------- */
/*                                    texts                                   */
/* -------------------------------------------------------------------------- */
.text-primary-header {
  color: $main-c;
  font-size: 2rem;
  font-weight: $bolder;
  line-height: 1.4;
  &--joincompetition {
    font-size: 1.75rem;
    @include tablet {
      font-size: 1.5rem;
      width: 17rem;
    }
    @include tablet-sm {
      font-size: 1.3rem;
      width: 17rem;
    }
    @include tablet-xs {
      font-size: 1.1rem;
      width: 17rem;
    }
    @include mobile-xxs {
      font-size: 0.9rem;
      width: 17rem;
    }
  }
}
.text-blue-header {
  font-size: 26px;
  font-weight: $bolder;
  // color: $blue-title-c;
  color: $white;
}
.text-orange-header {
  color: $blue-title-c;
  font-size: 20.8px;
  // font-weight: $bolder;
  margin-block-end: 20px;
}
.blue-text {
  color: $blue-title-c;
}
.dark-blue-text {
  color: #181d25;
}

.text-red {
  color: $red;
}

.text-white {
  color: $white;
}

// input phone number
.InputPhone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ant-form-item {
    margin-bottom: 0 !important;
  }

  input::placeholder {
    color: $grey;
  }

  .auth__phoneError {
    // color: #ff4d4f;
    color: $red;
  }
  .antd-country-phone-input {
    border: 1px solid $light-grey !important;
    border-radius: 4px;
    width: 100% !important;
    &.hasError {
      &.ant-input-affix-wrapper {
        &:focus,
        &:active,
        &:visited {
          box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
          outline: none;
        }
      }
      input {
        border: none !important;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: auto !important;
      border-color: transparent !important;
      margin-bottom: 0;
    }
    .ant-select .ant-select-arrow {
      transform: none;
    }
    .fp.eg {
      border-radius: 50%;
      line-height: 1.5em;
    }
  }
}

.ant-input-group {
  .ant-input-group-addon {
    background-color: white;
    border: none;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: none !important;
  }
  // .ant-input-focused,
  // .ant-input:focus {
  //   border: none !important;
  //   outline: none !important;
  //   border-color: transparent;
  // }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $light-grey !important;
}

/* -------------------------------------------------------------------------- */
/*                                   states                                   */
/* -------------------------------------------------------------------------- */
.error-message {
  font-size: 13.92px;
  color: #ff4d4f;
}

/* -------------------------------------------------------------------------- */
/*                                global setup                                */
/* -------------------------------------------------------------------------- */
.smooth-no-image {
  background-image: linear-gradient(90deg, #ccc, #999, #ccc);
  color: #fff;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.f-21 {
  font-size: 21px !important;
}
.f-22 {
  font-size: 22px;
}
.f-24 {
  font-size: 24px;
}
.f-25 {
  font-size: 25px;
}
.f-37 {
  font-size: 37px;
}
.f-31 {
  font-size: 31.25px;
}
.text-underline {
  text-decoration: underline;
}
.w-100 {
  width: 100%;
}
.w-20 {
  width: 20% !important;
}
.min-height-100vh {
  min-height: 100vh;
}
.ant-modal-body > * {
  width: 100%;
}
.modal-container {
  .ant-modal-content {
    border-radius: 22px;
    // width: 60%;
    // height: 100%;
    @include mobile-xxs {
      height: auto;
    }
  }
  .closeIcon {
    width: 17px;
  }
}
.joinCompetition-modal {
  width: 720px !important;
  & .ant-modal-content .ant-modal-body {
    padding: 24px 80px;
  }
  @include tablet {
    & {
      width: 520px !important;
      // padding: 24px 80px;
      font-size: 1rem !important;
      // width: 70%;
    }
    & .ant-modal-content .ant-modal-body {
      padding: 24px 50px;
    }
  }
  @include tablet-sm {
    & {
      width: 520px !important;
      // padding: 24px 80px;
      font-size: 0.8rem !important;
      // width: 70%;
    }
    & .ant-modal-content .ant-modal-body {
      padding: 24px 50px;
    }
  }
  @include tablet-xs {
    & {
      width: 420px !important;
      // padding: 24px 80px;
      font-size: 0.7rem !important;
      // width: 70%;
    }
    & .ant-modal-content .ant-modal-body {
      padding: 24px 40px;
    }
  }
  @include mobile-xxs {
    & {
      width: 350px !important;
      // padding: 24px 80px;
      font-size: 0.7rem !important;
      // width: 70%;
    }
    & .ant-modal-content .ant-modal-body {
      padding: 40px 0px 20px 0;
    }
  }
}
.modal_content {
  color: #356ab3;
  font-size: 25px;
  font-weight: bold;
  width: 60%;
  margin: auto;
  @include tablet-xs {
    & {
      font-size: 1.5rem;
      width: 70%;
    }
  }
  @include mobile-sm {
    & {
      font-size: 1.3rem;
      width: 80%;
    }
  }
  // //
  @include mobile-xxs {
    & {
      font-size: 1rem;

      width: 90%;
    }
  }
}

.light-blue-title {
  color: #255ca7;
}
.grey-title {
  color: #8e8e8e;
}
.bord-red {
  border: solid red 1px !important;
}
.bord-blue {
  border: solid blue 1px !important;
}

.w-20 {
  width: 20%;
}
// @include to-tablet-sm {
//   .modal-container {
//     width: 90%;
//   }
// }
video::-webkit-media-controls {
  display: none !important;
}
// video::-webkit-media-controls-panel {
//   background-color: #912525f8;
// }

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
  &:hover {
    border-color: transparent !important;
  }
  // border-right-width: 1px;
}

.ant-input-number.ant-input-number-in-form-item {
  height: 52px !important;
  display: flex;
}

.custom-container {
  padding-inline: 15px;
  margin-inline: auto;

  @include from-tablet-sm {
    padding-inline: 55px;
  }

  @include from-desktop-sm {
    padding-inline: 55px;
  }
}
