.static {
	&__header {
		background: #fff;
		.ant-select-selector {
			border: none !important;
			color: #272e3a;
			font-size: 18px;
		}
	}
	&__footer {
		background: #181d25;
		box-shadow: 0 3px 6px #00000029;
		color: #fff;
	}
	&__content {
		color: #284e83;
		.kidMomSection {
			background: #fdeded;
		}
		.laptop {
			margin-bottom: 120px;
		}
		@include mobile-xs {
			.laptop {
				width: 100%;
			}
		}
	}
}
