.watch-now-onMobile {
  margin-top: 100px;
  .getTheApp_title {
    // color: #356ab3;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }
}

.watch-now {
  // background: #fafafa;

  .video-details {
    margin-inline: 1rem;

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-block: 1rem;
    }

    &__header {
      display: flex;
      align-items: center;

      h4 {
        color: $white;
        font-size: 1.25rem;
        margin-bottom: 0;
        // color: #1f252e;
        margin-inline-end: 1.4375rem;
        display: flex;
        align-items: baseline;
        &::before {
          content: "";
          display: block;
          width: 7px;
          height: 1em;
          background: $main-c;
          margin-inline-end: 1rem;
          align-items: center;
        }
      }
    }

    &__season {
      color: $blue-title-c;
      font-size: 1.0625rem;
    }

    &__my-list {
      display: flex;
      align-items: center;
      font-size: 1.15rem;
      img {
        margin-inline-end: 0.24rem;
        transform: scale(0.85);
        transition: $trans;
      }
      &:hover {
        img {
          transform: scale(1);
        }
      }
    }

    &__categories {
      display: flex;
      margin-bottom: 1.5rem;

      li {
        margin-inline-start: 1rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        // color: #1f252e;
        color: $white;

        &::before {
          content: "";
          width: 0.45rem;
          height: 0.45rem;
          background-color: rgb(217, 202, 37);
          border-radius: 50%;
          margin-inline-end: 0.5rem;
        }

        &:first-of-type {
          margin-inline-start: 0;
        }
      }
    }

    &__description {
      margin-bottom: 1.5rem;
      color: $white;
      p {
        font-size: 1rem;
        // color: #1f252e;
        line-height: 1.5;
        margin-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    &__langs {
      margin-bottom: 1.5rem;

      h5 {
        color: $blue-title-c;
        font-size: 1.25rem;
        font-weight: $bolder;
      }
      .lang__wrapper {
        display: flex;

        li {
          display: flex;
          align-items: center;
          &:first-of-type {
            margin-inline-end: 3.125rem;
          }
          strong,
          span {
            color: #1f252e;
            font-size: 14px;
            margin-inline-end: 0.35rem;
          }
          span {
            &:after {
              content: ",";
            }
            &:last-of-type {
              &::after {
                content: "";
              }
            }
          }
          strong {
            font-weight: bold;
          }
        }
        .icon-wrapper {
          width: 1.5rem;
          margin-inline-end: 0.25rem;
          img {
            width: 100%;
          }
        }
      }
    }

    &__cast {
      margin-bottom: 1.5rem;

      &__header {
        h5 {
          font-size: 1.25rem;
          font-weight: $bolder;
          // color: $blue-title-c;
          color: $white;
          margin-bottom: 1rem;
        }
      }
      .cast {
        &__members {
          display: flex;
          flex-wrap: wrap;
        }
        &__member {
          width: 6.25rem;
          text-align: center;
          margin-inline-end: 1rem;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;

          align-items: center;
          .image-wrapper {
            width: 100%;
            height: 6.25rem;
            margin-bottom: 1rem !important;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          h6 {
            font-size: 1rem;
            color: $white;
            font-weight: $bolder;
          }
        }
      }
    }
  }
}
.overlay,
.overlay-replay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Adjust the alpha (fourth parameter) for transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  // z-index: 9999; /* Ensure it's above other content */
}

/* Styling for the content inside the overlay */
.overlay-content {
  text-align: center;
}

@include to-tablet-lg {
  .watch-now {
    .video-details {
      &__head {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &__header {
        margin-block-end: 1rem;
      }
      &__langs {
        .lang__wrapper {
          flex-direction: column;
          li {
            margin-block-end: 0.5rem;
            &:last-of-type {
              margin-block-end: 0rem;
            }
          }
        }
      }
    }
  }
}
