.subscription_plan {
  margin-top: 1rem;
  .title {
    // color: #356ab3;
    color: $white;
    font-size: 25px;
    font-weight: 400;
    // margin: 1rem 0 79px 0;
  }
  .switcher {
    // background: #d5edf0;
    background-color: rgba($color: #356ab326, $alpha: 0.15);

    border-radius: 13px;
    display: inline-flex;
    padding: 4px;
    .option {
      padding: 0.3rem 2rem;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      // color: #000;
      color: #fff;
      &.selected {
        border-radius: 13px;
        color: #fff;
        background: #356ab3;
      }
    }
  }
  .myPlan {
    .myPlan-title {
      color: #2699fb;
      font-size: 25px;
      font-weight: bold !important;
    }
    .myPlan-container {
      width: 418px;

      position: relative;
      padding-inline: 8px;
      padding-block: 16px;
      .discount-box {
        position: absolute;
        top: 0px;
        right: 0px;
        .discount-container {
          position: relative;
          .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            width: 100%;
          }
        }
      }
    }
    .package-card {
      // width: 418px;
      width: 100%;
      border-radius: 22px;
      box-shadow: 0 3px 6px #00000029;
      padding: 1rem;
      display: inline-block;
      background: #d8d8d8;
      &.currentPackage {
        background: #fff;
        .package-name {
          color: #ed2024;
        }
        .package-billedEvery {
          color: #8d99af;
        }
        .package-amount {
          .amount {
            color: #5f5555;
          }
        }
      }
      &.freeDays {
        background: #cce2ff;
        .package-name {
          color: #ed2024;
        }
      }
      .package-name {
        font-size: 20px;
        margin-top: 10px;
        font-weight: bold;
        color: #8e8e8e;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
      }
      .package-amount {
        .amount {
          font-size: 31.25px;
          color: #8e8e8e;
          font-weight: bold;
        }
      }
      .package-billedEvery {
        font-size: 16px;
        font-weight: 400;
        color: #8e8e8e;
      }
    }
    .row-container {
      padding-inline: 80px;
    }
    .note {
      // color: #8d99af;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-block-start: 5px;
      width: 313px;
    }
  }
  .renewSubscriptionAutomatically {
    font-size: 20px;
  }
  .btn-plan {
    width: auto;
    padding: 0px 60px;
    border-width: 4px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .payment-details {
    &__title {
      font-size: 25px;
      color: #2699fb;
      font-weight: bold;
    }
    &__noCard {
      color: #356ab3;
      font-size: 25px;
      font-weight: bold;
    }
    .number {
      font-size: 20px;
      color: #5f5555;
    }
    .expireIn {
      color: #8e8e8e;
    }
    .delete_btn {
      font-size: 20px;
      color: #ed2024;
      font-weight: bold;
      margin-inline-end: 20px;
    }
    .divider {
      content: "";
      display: inline-block;
      height: 69px;
      width: 2px;
      background: #e2e0e0;
      margin: 0 20px;
    }
    .ccContainer {
      width: max-content;
      border-radius: 22px;
    }
    .ccImage {
      margin: 0 20px;
    }

    @include mobile-xs {
      .ccContainer {
        .number {
          font-size: 14px;
        }
        .delete_btn {
          margin-inline-end: 5px;
          font-size: 14px;
        }
        .ccImage {
          height: 42px;
          margin: 0 5px;
        }
        .divider {
          margin: 0 5px;
        }
      }
    }
  }
}

@include to-tablet-lg {
  .subscription_plan {
    .myPlan {
      .row-container {
        padding: 0;
      }
    }
  }
}
