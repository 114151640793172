.PersonalInfo {
  margin-block-start: 50px;
  &__title {
    color: $white;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    row-gap: 10px;
  }
  &__input {
    width: 45% !important;
    input,
    .ant-select-selector {
      height: 52px !important;
    }
  }
  &__PesonalInfo__input-radius {
    border-radius: 40px !important;
  }
  &__SaveChanges {
    width: 14.375rem;
    margin-top: 4rem;
  }
  &__SaveChanges > button {
    width: 100%;
  }
}
#root
  .PersonalInfo__inputs
  > div:nth-child(3)
  > div
  > div
  > div
  > div
  > div
  > span {
  width: 100%;
}

//media query
@include to-mobile-sm {
  .PersonalInfo {
    &__title {
      text-align: center;
    }
    &__inputs {
      flex-direction: column;
      width: 100%;
      margin-inline: auto !important;
    }
    &__input {
      width: 95% !important;
    }
  }
}
