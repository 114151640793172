.manage-account {
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    word-spacing: 0.1875rem;
    color: $blue-title-c;
  }
  &__allAvatars {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 5.125rem;
  }
  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-end: 1.25rem;
  }
  &__add {
    img {
      width: 140px;
      height: 140px;
    }
  }
  &__figure {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    padding: 0.5rem;
    position: relative;
    z-index: 1;
    .edit-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      z-index: 3;
    }

    &.show-edit {
      &::after,
      .edit-icon {
        visibility: visible;
        opacity: 1;
      }
    }
    .delete-icon {
      width: 30px;
      position: absolute;
      top: -10px;
      right: -10px;
    }

    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 80%;
      transform: translate(-50%, -50%);
      background-color: rgba($main-blue, 0.15);
      z-index: 2;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      position: absolute;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
  }

  &__avatar__name {
    font-size: 24px;
    font-weight: $bolder;
    color: #c1b7b7;
    transition: all 0.5s ease-in-out;

    &.active {
      // color: $main-blue;
      color: $red;
    }
  }
  &__manage {
    font-weight: 400;
    font-size: 1.125rem;
    color: $main-c;
  }
  &__manage-text {
    border-bottom: 0.0625rem solid;
    border-bottom-color: $main-c;
  }
}
// mediaQuery
@include to-mobile-sm {
  .manage-account {
    &__avatar__image {
      width: 100%;
    }
    &__avatar__name {
      font-size: 1.125rem;
    }
    &__title {
      font-size: 1.125rem;
    }
  }
}
