.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  position: absolute;
  top: 0;
  z-index: 999;
}

.spinner.display-none {
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  position: absolute;
  top: 0;
  z-index: 999;
  transition: all 0.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}
