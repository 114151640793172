.otpInput {
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  font-size: 2rem;
  // font-weight: bold;
  text-align: center;
  border-radius: 4px;
  border: 1px solid $white;
  // color: $red;
  color: $white;
  background-color: rgba($color: #fff, $alpha: 0.2);

  &:focus {
    background-color: rgba($color: #fff, $alpha: 0.2);
    outline: transparent;
  }
}

.otpLight {
  color: $dark;
  border: 1px solid $main-blue;
  background-color: rgba($color: $main-blue, $alpha: 0.2);
  &:focus {
    background-color: rgba($color: $main-blue, $alpha: 0.2);
    outline: transparent;
  }
}

.otpContainer {
  display: flex;
  flex-wrap: nowrap;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 450px) {
  .otpInput {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    padding: 8px;
    margin: 0 0.7rem;
  }
}
@media only screen and (max-width: 400px) {
  .otpInput {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}
