.selector_container {
  display: flex;
  flex-direction: column;
  // width: 400px;
  margin: 0 auto;
  &_label {
    font-size: 1.5rem;
    // color: #356ab3;
    color: $accountProfile-pc;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 14px;
  }
  &-phoneinput {
    margin: 0 auto;
    width: 100%;
    // border: 4px solid #356ab3;
    border: 4px solid rgba($color: #000000, $alpha: 0.12);
    border-radius: 8px;
    padding: 16px;
    // color: $main-blue;
    color: $grey;
    &:focus {
      outline: none;
    }
    @include tablet {
      padding: 15px;
    }
    @include tablet-sm {
      padding: 14px;
    }
    @include tablet-xs {
      padding: 13px;
    }
    @include mobile-xs {
      // width: 300px;
    }
    @include mobile-xxs {
      padding: 12px;
      // width: 250px;
      // width: 110%;
    }
  }
  &-phoneinput_page {
    width: 400px;
    @include mobile-xs {
      width: 300px;
    }
    @include mobile-xxs {
      width: 250px;
      // width: 110%;
    }
  }
}
.transition-border {
  transition: border-color 0.3s ease-in-out; /* You can adjust the transition duration as needed */
}
.phoneinput_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
}

.warning_text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 40px;
  width: 32.8125rem;
  margin: 0 auto 40px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  color: #666666;
  span {
    padding: 2px 20px;
    @include mobile-s {
      padding: 2px 0px;
    }
  }
  @include tablet {
    width: 25rem;
  }

  @include to-tablet-sm {
    width: 25rem;
  }

  // @include tablet-xs {
  //   width: 20rem;
  // }

  // @include mobile-sm {
  //   width: 20rem;
  // }
  @include mobile-xxs {
    width: 20rem;
  }
  @include mobile-s {
    width: 15rem;
  }
}

.subscribe-btn {
  font-family: "Roboto", sans-serif;
  font-size: 27px;
  font-weight: 700;
  padding: 15px;
  background: $main-grad;
  color: white;
  border: none;
  width: 400px;
  border-radius: 999px;
  margin-bottom: 0.75rem;
  transition: background 0.3s ease;
  &:focus {
    background: $main-grad;
    color: white;
  }

  &_verify {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color: white;
    height: 4.5rem;
    font-size: 27px;
    border-radius: 999px;
    // width: 100%;
    padding: 0;
    @include mobile-xs {
      height: 3.5rem;
    }
    @include mobile-xxs {
      height: 3rem;
    }
  }
  &_joinCompetition {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color: white;
    height: 4.5rem;
    font-size: 27px;
    border-radius: 999px;
    width: 100%;
    padding: 0;

    &:not(:disabled):hover {
      color: white !important;
      background: linear-gradient(
        180deg,
        rgb(254, 167, 168) 0%,
        rgb(236, 49, 52) 100%
      ) !important;
    }
    &:active,
    &:visited {
      color: whitesmoke !important;
      background: $hover-grad100 !important;
    }
    @include tablet {
      font-size: 18px !important;
      height: 3.6rem !important;
    }
    @include tablet-sm {
      font-size: 18px !important;
      height: 3.2rem !important;
    }
    @include tablet-xs {
      font-size: 16px !important;
      height: 2.6rem !important;
    }
    @include mobile-xxs {
      font-size: 14px !important;
      height: 2.6rem !important;
    }
  }
  &:not(:disabled):hover {
    color: white !important;
    background: linear-gradient(
      180deg,
      rgb(254, 167, 168) 0%,
      rgb(236, 49, 52) 100%
    ) !important;
  }
  &:active,
  &:visited {
    color: whitesmoke !important;
    background: $hover-grad100 !important;
  }
  @include mobile-xs {
    font-size: 20px;
    width: 300px;
  }
  @include mobile-xxs {
    font-size: 20px;
    width: 250px;
  }
  // &:hover {
  //   background: $hover-grad50;
  // }
  // &:active {
  //
  // }
  &:disabled {
    &,
    &:focus,
    &:hover {
      background: #ccc !important;
      color: #999999e7 !important;
      cursor: not-allowed;
    }
  }
}
.disabled-input {
  background-color: #f0f0f0; /* Background color when disabled */
  color: #999; /* Text color when disabled */
  cursor: not-allowed; /* Change the cursor to not-allowed */
  border: 4px solid #ccc; /* Border color when disabled */
  /* Add any additional styles you want for disabled input here */
}

.error-message {
  margin: 0 auto;
  text-align: left;
  line-height: 1.39375;
  font-weight: 700;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 16px;
  width: 400px;
  @include mobile-xs {
    font-size: 14px;
    width: 300px;
  }
  @include mobile-xxs {
    font-size: 14px;
    width: 250px;
  }
  &--verification {
    width: 545px;
    @include mobile-xs {
      font-size: 14px;
      width: 300px;
    }
    @include mobile-xxs {
      font-size: 14px;
      width: 250px;
    }
    @include to-tablet-xs {
      font-size: 14px;
      width: 415px;
    }
    @media only screen and (max-width: 430px) {
      font-size: 14px;
      width: 300px;
    }
  }
  &--warning {
    color: #666666;
    font-weight: 500;
  }
}
.error-message--joinCompetition {
  text-align: left;
  line-height: 1.39375;
  font-weight: 700;
  margin-top: 4px;
  font-size: 1rem !important;
  color: #ed2024 !important ;
  margin-bottom: 0;
  @include tablet {
    font-size: 0.9rem !important;
    height: 2.5rem;
  }
  @include tablet-sm {
    font-size: 0.9rem !important;
    height: 2rem;
  }
  @include tablet-xs {
    font-size: 0.8rem !important;
    height: 1.8rem;
  }
  @include mobile-xs {
    font-size: 0.7rem !important;
    height: 1.8rem;
  }
}

.error-input {
  border: 4px solid #ed2024;
}

.PinCodeInput {
  // width: 4rem;
  // height: 4rem;
  // margin: 0 1rem;
  // font-size: 1rem;
  // text-align: center;
  // border-radius: 4px;
  // border: 1.2px solid #356ab3;
  // background-color: #d7e1f0;
  // color: rgba(0, 0, 0, 0.85);

  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  font-size: 2rem;
  // font-weight: bold;
  text-align: center;
  border-radius: 4px;
  border: 1px solid $white;
  color: $red;
  background-color: rgba($color: #fff, $alpha: 0.2);

  &:focus {
    background-color: rgba($color: #fff, $alpha: 0.2);
    outline: transparent;
  }
  &:focus {
    outline: none;
  }

  @include to-tablet-xs {
    width: 3rem;
    height: 3rem;
    font-size: 14px;
    margin: 0 0.8rem;
  }

  // @include to-mobile-sm {
  //   width: 2.5rem;
  //   height: 2.5rem;
  //   font-size: 14px;
  //   margin: 0 0.6rem;
  // }
  @media only screen and (max-width: 430px) {
    width: 2.6rem !important;
    height: 2.6rem !important;
    font-size: 14px;
    margin: 0 0.5rem !important;
  }
}
.pinCodeContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 450px) {
  .PinCodeInput {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    padding: 8px;
  }
}

.modal-input-container {
  color: #356ab3;
  font-size: 25px;
  font-weight: 700;
  margin: auto;
  width: 60%;
  @include tablet {
  }
  @include tablet-sm {
  }
  @include tablet-xs {
  }
  @include mobile-xxs {
    width: 70%;
  }
}

.auth__timer {
  font-size: 1.2rem;
  color: $white;
}
