@font-face {
  font-family: "bahnschrift";
  src: url("./assets/fonts/bahnschrift/BAHNSCHRIFT\ 1.TTF");
}

@font-face {
  font-family: "Baloo Bhaijaan 2";
  src: url("./assets/fonts/baloobhaijaan2/baloobhaijaan2_regular.ttf");
}

// font-family: "Comic Neue", "Baloo Bhaijaan 2", cursive;
html,
body,
* {
  margin: 0;
  font-family: "bahnschrift", sans-serif;
  [lang="ar"] & {
    font-family: "Baloo Bhaijaan 2", serif;
  }
}
body {
  // background-repeat: no-repeat;
  // background-image: url("./assets/images/amuse_background.png");
  // background-image: url("./assets/images/website-bg.png");
  // background-position: center center;
  // background-size: cover;
  background-color: #141a21;
}
code {
  font-family: "bahnschrift", sans-serif;
  [lang="ar"] & {
    font-family: "Baloo Bhaijaan 2", serif;
  }
}
