/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */
$input-placeholder-c: #27a2d9;
$blue-title-c: #356ab3;
$orange-title-c: #f58234;
$input-border-c: #356ab3;
$link-c: #27a2d9;
$link-new-c: #0370ff;
$white-blue: #2699fb;
$main-c: #ed2024;
$accountProfile-pc: #fafafa;
$accountProfile-sc: #f69553;
$error-color: #ff4d4f;

$white: #fff;
$red: #ff4343;
$main-blue: #356ab3;
$dark: #1e2630;
$grey: #666666;
$light-grey: #d7d7d7;
$light-white: #f6f6f6;

$main-grad: linear-gradient(
  180deg,
  rgba(255, 170, 172, 1) 0%,
  rgba(237, 32, 36, 1) 100%
);
$hover-grad50: linear-gradient(
  180deg,
  rgb(254, 167, 168) 0%,
  rgb(236, 49, 52) 100%
);
$hover-grad100: linear-gradient(
  180deg,
  rgb(254, 201, 202) 0%,
  rgb(237, 68, 71) 100%
);

/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */
$bolder: 700;

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
$trans: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
$box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$navbar-height: 88px;
