.amuse-nav {
  z-index: 76 !important;
  // height: 89px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.678),
    rgba(0, 0, 0, 0.692) 23%,
    rgba(81, 81, 81, 0.426)
  );
  z-index: 2;
  color: white;
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

  .gif_player img {
    width: 3.5rem;
    cursor: pointer;
  }

  &--white {
    background: white !important;
    .amuse-nav__ul-center,
    .amuse-nav__ul-end {
      .item {
        color: #333;
      }
    }
    .amuse-nav__ul-center-mobile,
    .amuse-nav__ul-end-mobile {
      .item {
        color: red;
      }
    }
  }
  &-btnicongift-container {
    display: flex;
    align-items: center;
    .item {
      &-btn {
        align-self: flex-end;
      }
      &-gift {
        align-self: flex-end;
        transform: translateY(3px);
      }
      &-lang {
        align-self: flex-end;
      }
    }
  }
  &__search-container {
    border-radius: 46px;
    border: 1px solid #ed2024;
    align-items: center;
    background: #fff;
    flex-direction: row-reverse;
    transition: all 0.5s ease-in-out;
    & input,
    & input:hover {
      border: none !important;
      border-radius: 42px;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &__search {
    .search__input-hide {
      width: 30px;
      opacity: 0;
      margin: 0px 10px;
      transition: 0.3s;
    }
    .search__input-show {
      width: 350px;
      opacity: 1;
      margin: 0px 10px;

      transition: 0.3s;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  padding-block: 1rem;
  &__logo {
    margin-inline-end: 1.5rem;
  }

  &__menus {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  &__menus-mobile {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__ul-center,
  &__ul-end {
    display: flex;
    align-items: center;

    .item {
      margin-inline: 0.75rem;
      color: white;
      img {
        margin-bottom: 0 !important;
      }
      &:last-child {
        margin-inline-end: 0;
      }
    }
  }
  &__ul-center-mobile,
  &__ul-end-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .item {
      margin-block: 0.5rem;
      color: white;
      img {
        margin-bottom: 0 !important;
      }
      &:last-child {
        margin-inline-end: 0;
      }
    }
  }
  &__search-gift-mobile {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: end;
    margin-right: 1rem;
    margin-left: 1rem;
    @include to-mobile-sm {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__search {
    position: relative;
    background: transparent;
    input {
      border: none;
      width: 0;
      border-inline-width: 0;
      border-top-width: 0;
      border-bottom: 1px solid transparent;
      transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      padding-bottom: 0.5rem;
      padding-inline-end: 1.5rem;
      background: transparent;

      &:focus-visible {
        outline: none;
      }
    }
    &.active {
      input {
        width: 100%;
        border-bottom: 1px solid white;
      }
    }

    &__img {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(0.75) translateY(-65%);
    }
  }

  &__img,
  &__search {
    padding: 0.25rem;
  }
  &__avatar {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &__menu {
    .dropdown {
      &-menu {
        padding-block: 0;
        border-color: transparent;
        box-shadow: $box-shadow;
      }
      &.show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0 transparent !important;
      }
    }
    button,
    .btn-primary:active {
      padding: 0;
      background: transparent;
      border-color: transparent;
      box-shadow: 0 0 0 0 transparent;
    }

    .dropdown-item {
      transition: $trans;
      &:hover,
      &:active,
      &:focus {
        background: rgba($orange-title-c, 0.35);
        color: $orange-title-c;
      }
    }

    .btn-primary:focus,
    .btn-primary:hover {
      background: transparent;
      border-color: transparent;
      color: white;
    }

    .btn-primary {
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 0 0 0 transparent !important;
        color: white;
      }
    }

    &.white-nav {
      .btn-primary {
        color: #7b7b7b;
        &:hover,
        &:focus,
        &:active {
          color: #7b7b7b;
        }
      }
    }

    &.white-nav-mobile {
      .btn-primary {
        color: red;
        &:hover,
        &:focus,
        &:active {
          color: red;
        }
      }
    }
  }
  .search-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__btn {
    width: 215px !important;
    width: 100px !important;
    font-size: 0.95rem;
  }

  &-white {
    transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    background: white;
    .amuse-nav__ul-center {
      .item {
        color: #707070;
      }
    }
    .amuse-nav__search {
      &.active {
        input {
          border-bottom: 1px solid #707070;
        }
      }
    }
  }

  @include to-mobile-sm {
    position: relative;
  }
}
.navbar-search-dropdown {
  position: fixed;
  width: 350px;
  max-height: 470px;
  overflow: auto;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-shadow: 0 3px 6px #00000029;
  .ant-dropdown-menu-root {
    min-height: 300px;
  }
  .movie-cover {
    width: 107px;
    // height: 85px;
    aspect-ratio: 4/3;
    // border-radius: 25px;
    border-radius: 8px;
    padding: 1px;
  }
  .title {
    font-size: 21px;
    color: #000000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no-results-found {
    .title {
      font-size: 21px;
      color: #356ab3;
      font-weight: bold;
    }
  }
}
.dropdown-menu-item-ar {
  text-align: right !important;
}

.container-nav {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.678),
    rgba(0, 0, 0, 0.692) 23%,
    rgba(81, 81, 81, 0.426)
  );
}

.main-header__lg {
  display: none !important;

  &--view {
    display: none !important;
  }

  @media (min-width: 768px) {
    & {
      display: flex !important;
    }
  }

  @media (min-width: 992px) {
    &--view {
      display: block !important;
    }
  }
}

.main-header__tablet {
  display: none !important;
  @media (min-width: 768px) and (max-width: 991px) {
    & {
      display: flex !important;
    }
  }
}

.main-header__mobile {
  display: none !important;
  @media (max-width: 767px) {
    & {
      display: flex !important;
    }
  }
}
