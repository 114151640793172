.otp {
  &__errorMessage {
    text-align: center;
    color: red;
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.parentalCode-btn {
  width: 313px;
  margin-top: 42px;
}
