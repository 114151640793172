.home-banner {
  // height: 92vh;
  // width: 100vw;
  width: 100%;
  // min-height: 28.125rem;
  aspect-ratio: 16 / 9;

  // padding-block-start: 1.25rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: -1;
  }

  &.rtl {
    &::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.95) 100%
      );
    }
  }

  .container,
  .wrapper {
    height: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  &__data {
    // flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: $navbar-height;

    @include from-tablet-lg {
      padding-top: 140px;
    }

    @include from-desktop-sm {
      padding-top: 194px;
    }

    @include to-mobile-sm {
      padding-top: 0;
      scale: 0.8;
    }
    // padding-top: 2rem
  }

  &__season {
    margin-block-end: 1.25rem;
    font-size: 2.8rem;
    color: #fff;

    @include to-mobile-sm {
      // font-size: 2.rem;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__categories {
    margin-block-end: 1.25rem;

    ul {
      display: flex;

      li {
        margin-inline-start: 1rem;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: white;

        &::before {
          content: "";
          width: 0.45rem;
          height: 0.45rem;
          background-color: rgb(217, 202, 37);
          border-radius: 50%;
          margin-inline-end: 0.5rem;
        }

        &:first-of-type {
          margin-inline-start: 0;
        }
      }
    }
  }

  &__watch-now-add-list {
    margin-block-end: 1.25rem;
    ul {
      display: flex;

      li {
        margin-inline-end: 1rem;
        color: white;
        display: flex;
        align-items: center;
        font-size: 1.0625rem;
        .watch-now {
          &__text {
            line-height: 1;
            margin-top: 1rem;
          }
          &__small {
            font-size: 0.85rem;
            line-height: 1;
          }
        }

        &:hover {
          img {
            transform: scale(1.2);
          }
        }
        img {
          margin-inline-end: 0.5rem;
          transition: $trans;
        }
      }
    }
  }

  &__desc {
    margin-block-end: 1.8125rem;
    flex-basis: 20%;

    p {
      max-width: 472px;
      font-size: 0.875rem;
      color: white;
      margin-bottom: 0 !important;

      @include to-tablet-sm {
        max-width: 413px;
        display: none;
      }
      @include to-tablet-xs {
        max-width: 334px;
      }
      @include to-mobile-sm {
        max-width: 269px;
        // max-width: 100%;
        display: none;
      }
      @include to-tablet-lg {
        display: none;
      }
    }
  }

  &__navigators {
    margin-top: auto;
    // justify-self: flex-end;
    margin-bottom: 1rem;
    .figure-holder {
      width: 135px;
      height: 135px;
      @include tablet {
        width: 115px;
      }
    }
    figure {
      width: 96px;
      height: 96px;

      position: relative;
      margin: 0 !important;
      transition: all 0.5s ease;
      cursor: pointer;
      .image-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: "";
        width: 0%;
        height: 2px;
        background: $main-c;
        bottom: -1rem;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0.5rem;
        transition: $trans;
      }

      &.active-banner-wrapper {
        &::after {
          width: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
    figure:hover {
      width: 135px;
      height: 135px;

      @include to-tablet-lg {
        width: 105px;
        height: 105px;
      }
    }
  }
  &__navigator {
    height: 50px;
    // border: solid white 1px;
    padding-inline: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include to-mobile-s {
      margin-top: -20px;
    }
  }
  &__nav-span {
    width: 100%;
    // border-width: px;
    border-style: solid;
    border-radius: 5px;
  }
  .navigator-color {
    border-color: white;
  }
  .navigator-active {
    border-color: red;
  }
  .tab {
    width: 50px;
  }
  .mob {
    width: 35px;
  }
}

// generies
.generies {
  margin-top: 24px;
  &__grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    align-items: flex-end;
    justify-content: center;
    margin: auto;
    width: 66.28%;
    @include from-desktop-sm {
      width: 617.54px;
    }
  }

  &__title {
    color: $blue-title-c;
    // font-size: 1.25rem;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &__fig {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &-soon {
      width: 30%;
      margin: auto;
    }

    .image-wrapper {
      img {
        // width: 114.49px;
        // height: 114.49px;
        width: 83px;
        height: 83px;
      }
      // &--know {
      //   img {
      //     width: 71px;
      //     @include tablet {
      //       width: 60px;
      //     }
      //     @include to-mobile-s {
      //       width: 49px;
      //     }
      //   }
      // }
      // &--store {
      //   img {
      //     width: 107px;
      //     @include tablet {
      //       width: 90px;
      //     }
      //     @include to-mobile-s {
      //       width: 84px;
      //     }
      //   }
      // }
      // &--anime {
      //   img {
      //     width: 150px;
      //     @include tablet {
      //       width: 130px;
      //     }
      //     @include to-mobile-s {
      //       width: 110px;
      //     }
      //   }
      // }
      // &--movies {
      //   img {
      //     width: 86px;
      //     @include tablet {
      //       width: 77px;
      //     }
      //     @include to-mobile-s {
      //       width: 56px;
      //     }
      //   }
      // }
      // &--series {
      //   img {
      //     width: 110px;
      //     @include tablet {
      //       width: 90px;
      //     }
      //     @include to-mobile-s {
      //       width: 78px;
      //     }
      //   }
      // }
    }

    figcaption {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 12.55px;
      color: $white;
      white-space: nowrap;
      @include mobile-xxs {
        font-size: 12px;
      }
      &.active {
        color: $main-c;
      }
    }

    &:hover {
      figcaption {
        color: $main-c;
      }
    }
  }

  .item {
    text-align: center;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    background: rgb(220, 220, 220);
  }

  &__figure {
    flex-basis: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    img {
      width: 4.6875rem;
      object-fit: "cover";
    }
  }

  &__name {
    flex-basis: 30%;
    color: white;
  }

  @include to-mobile-sm {
    // margin-top: 70px;
  }
}
.item__container {
  .react-multi-carousel-list {
    overflow-x: hidden;
    // overflow-y: auto;
  }
}
// generies-videos
.generies-videos {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.8125rem;
  }
  &__carousel-container {
    // background-color: red;
    // width: max-content;
    // height: 262px;
    // width: 102%;
    // overflow-x: hidden;
    // // overflow-y: unset;
    // overflow-y: hidden;

    overflow: hidden;
    max-width: 100%;

    // @include special {
    //   height: 300px;
    // }
    .react-multi-carousel-track > li:first-child > div {
      padding-inline-start: 0.5rem;
      // padding-inline-start: calc(217px / 2);
    }
    .react-multi-carousel-track {
      align-self: self-start;
      // gap: 30px;
      // gap: 4rem;
      // gap: 1rem;
      gap: 34.5px;
      display: flex;
      // justify-content: space-between !important;
      align-items: center;

      .react-multi-carousel-item {
        width: auto !important;
      }
    }
    .react-multiple-carousel__arrow {
      z-index: 60;
    }
    .react-multiple-carousel__arrow::before {
      font-size: 25px;
      background-color: rgba(0, 0, 0, 0.5);
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
    }
    .react-multiple-carousel__arrow:hover::before {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left,
    .react-multiple-carousel__arrow--right:hover,
    .react-multiple-carousel__arrow--left:hover {
      background: none;
    }
  }
  &__dots {
    .react-multi-carousel-dot {
      button {
        outline: none;
        border: 1px solid $red;
        width: 30px;
        height: 3px;
        border-radius: 0;
      }
      &.react-multi-carousel-dot--active {
        button {
          background: #ed2024;
        }
      }
    }
  }

  &__item {
    // margin-bottom: 32px;
    // margin-bottom: -20px;
    height: 240px;
    // margin-bottom: 15px;
  }

  &__no-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    // color: $blue-title-c;
    color: $white;
    margin-block: 2rem;
  }

  .item__header {
    display: flex;
    justify-content: space-between;
    h2 {
      color: $white;
      // font-size: 2.25Srem;
      // font-size: 40px;
      // font-size: 32px;
      font-size: 25px;
      font-weight: 700;
      @include to-tablet-sm {
        // font-size: 1.8rem;
        font-size: 1.6rem;
      }
    }
    p.see-all {
      font-size: 1.0625rem;
      cursor: pointer;
    }
  }
}

@include to-tablet-sm {
  .generies {
    &__grid-container {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .generies__fig .image-wrapper img {
    width: 80px;
    height: 80px;
  }
}

@include to-mobile-sm {
  .generies {
    &__grid-container {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .generies__fig .image-wrapper img {
    width: 60px;
    height: 60px;
  }
}

@include to-mobile-xs {
  .generies__fig .image-wrapper img {
    width: 40px;
    height: 40px;
  }
}
