.change-Password {
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    width: 50%;
  }
  &__form-item {
    input {
      height: 52px;
      // width: 519px;
    }
  }
}
//media query
@include to-mobile-sm {
  .change-Password {
    &__content {
      width: 90%;
    }
  }
}
@include to-tablet-sm {
  .change-Password {
    &__content {
      width: 75%;
    }
  }
}
