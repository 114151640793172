.subscription {
  .choose-plan {
    // margin-top: 10rem;
    .main-title {
      font-size: 25px;
      color: #356ab3;
      font-weight: 600;
      margin: 1rem 0 79px 0;
    }
    .btn-plan {
      width: 100%;
      padding: 0px 60px;
      border-width: 4px !important;
      font-size: 16px !important;
      font-weight: bold !important;
      @include to-mobile-sm {
        & {
          padding: 0px 40px;
        }
      }
    }
    .package-card {
      min-width: 20%;
      background: #fff;
      position: relative;
      border-radius: 22px;
      box-shadow: 0 3px 6px #00000029;
      padding: 1rem 2rem;
      .discount-box {
        position: absolute;
        top: -13px;
        right: -8px;
        .discount-container {
          position: relative;
          .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            width: 100%;
          }
        }
      }
      &.selected {
        border: 4px solid #ed2024;
        box-sizing: border-box;
      }
      &:hover {
        background: #d6dade;
      }

      cursor: pointer;
      .package-name {
        font-size: 20px;
        color: #ed2024;
        font-weight: bold;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        // flex-wrap: nowrap;
        margin-top: 10px;
      }
      .package-amount {
        .amount {
          font-size: 31.25px;
          color: #5f5555;
          font-weight: bold;
        }
      }
      .package-billedEvery {
        color: #8d99af;
      }
    }
  }
  .payment {
    .main-title {
      font-size: 25px;
      color: #356ab3;
      font-weight: 700;
    }
    .sub-title {
      color: #2699fb;
      font-size: 20px;
      font-weight: 400;
    }
    .or-text {
      font-size: 20px;
    }
    .payment-box {
      margin-top: 5rem !important;
    }
    @include to-mobile-sm {
      .payment-box {
        flex-direction: column;
      }
      .main-title {
        margin-bottom: 20px;
      }
    }
  }
}
.fawry-modal {
  width: 573px !important;
  height: 626px;

  @include mobile-xxs {
    width: 286.5px !important;
    height: 446px !important;
  }
  .success-img {
    width: 88px;
    height: 88px;
    @include mobile-xxs {
      width: 44px;
      height: 44px;
    }
  }
  .ant-modal-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    padding: 3.75rem 2rem;
    @include mobile-xxs {
      padding: 1.875rem 1rem 1.875rem 1rem;
    }
    width: 100%;
    margin: auto;
    height: 100%;
  }
  .total {
    .title {
      font-weight: 700;
      font-size: 28px;
      color: #ed2024;
      line-height: 40px;
      @include mobile-xxs {
        line-height: 19.51px;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .value {
      color: #5f5555;
      font-size: 25px;
      font-weight: bold;
      @include mobile-xxs {
        line-height: 19.51px;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  .fawryCode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    .title {
      color: #5f5555;
      font-size: 28px;
      line-height: 40px;
      @include mobile-xxs {
        line-height: 25px;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .value {
      font-size: 31px;
      color: #ed2024;
      @include mobile-xxs {
        line-height: 25px;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
  .expirationDate {
    .title {
      color: #8e8e8e;
      font-size: 18px;
      margin-inline-end: 10px;
      @include mobile-xxs {
        line-height: 13px;
        font-weight: 400;
        font-size: 9px;
      }
    }
    .value {
      color: #8e8e8e;
      font-size: 20px;
      @include mobile-xxs {
        font-size: 14px;
      }
    }
  }
  .share-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    color: #5f5555;
    @include mobile-xxs {
      line-height: 17px;
      font-size: 12px;
    }
  }
}
.subscripe-now-modal {
  .ant-modal-body {
    padding: 32px 40px 32px 40px;
    width: 100%;
    margin: auto;
    @include to-mobile-sm {
      width: 100%;
    }
  }
  .text {
    color: #356ab3;
    font-size: 22px;
    font-weight: bold;
    @include to-mobile-sm {
      font-size: 20px;
    }
    @include to-mobile-xs {
      font-size: 16px;
    }
  }
}
.button-primary--signIn {
  font-size: 20px;
  padding: 0;
  height: 3rem;
  width: 20rem;
  @include to-mobile-sm {
    font-size: 18px;
    width: 17rem;
  }
  @include to-mobile-xs {
    font-size: 16px;
    height: 2.4rem;
    width: 14rem;
  }
}
