.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  z-index: 999;
  opacity: 1; /* Start with full opacity */
  transition: opacity 0.5s ease; /* Add a transition effect for opacity */
}

/* CSS to hide the splash screen */
.splash-screen.hidden {
  opacity: 0; /* Make it transparent when hidden */
  pointer-events: none; /* Disable interaction when hidden */
}

body.splash-overflow-hidden {
  overflow: hidden;
}
