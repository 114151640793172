.addNewPassword {
  &__container {
    display: flex;
  }
  &__center {
    margin-top: 70px;
    text-align: center;
  }
  &__Form {
    width: 40%;
    margin-inline: auto;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__Input {
    width: 100%;

    input {
      height: 52px;
    }
  }
}
// media query
@include to-mobile-sm {
  .addNewPassword {
    &__Form {
      width: 90%;
    }
  }
}
@include to-tablet-sm {
  .addNewPassword {
    &__Form {
      width: 70%;
    }
  }
}
