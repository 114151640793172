.myList {
  &__title {
    margin-block: 1rem 2rem;
  }
  &__title span {
    color: $main-c;
    font-size: 1.3rem;
    font-weight: bold;
  }
  &__all-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }
  &__item {
    width: calc(100% / 4);
    height: 250px;
  }
  &__item:hover {
    transform: translate();
  }
  &__image {
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 30px;
  }
}

.myList-container {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @media (max-width: 1343px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include to-tablet-lg {
    grid-template-columns: repeat(2, 1fr);
  }
  @include to-tablet-sm {
    justify-content: center;

    grid-template-columns: repeat(1, 1fr);
  }
  @include to-mobile-sm {
    justify-content: center;

    grid-template-columns: repeat(1, 1fr);
    // width: 75%;
  }
  // @include tablet {
  //   grid-template-columns: repeat(3, 1fr);
  // }
}

.skeleton-container {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
