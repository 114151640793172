// .gifts-page {
//   margin-top: -10px; //empty space between the nav and gift page section bug
//   &__camelImg {
//     overflow: hidden;
//     height: 245.9px;
//     transform: rotate(-3.1deg);
//     position: absolute;
//     top: 10%;
//   }
//   &__boyImg {
//     overflow: hidden;
//     height: 183px;
//     transform: rotate(4deg);
//     position: absolute;
//     top: 37.5%;
//   }
//   &__beboAskBelalImg {
//     margin-top: 2.2rem;
//   }
//   &__question-box {
//     margin-top: 2rem;
//     position: relative;
//     overflow-wrap: break-word;
//     // background-color: red;
//     height: 50vh;
//     background-image: url(../images/giftScreenSm.Png);
//     // background-image: url(../images/giftScreenLg.png);
//     // background-image: url("../images/arch-meduim.png");
//     // background-image: url("../images/arch-small.png");
//     background-size: contain;
//     background-repeat: no-repeat;
//     opacity: 0.97;
//     background-position: 50% 80%;

//     @media screen and (min-width: 400px) {
//       // background-image: url("../images/arch-large.png");
//       background-image: url(../images/giftScreenMd.png);
//     }

//     @media screen and (min-width: 440px) {
//       // height: 40vh;
//       background-image: url(../images/giftScreenLg.png);
//       // background-position: 50% 80%;
//     }

//     // @media screen and (max-width: 767px) {
//     // }

//     &__redImage {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 297px;
//       // background-image: url(../images/giftScreenLg.png);
//       background-size: 100% 297px;
//       opacity: 0.97;
//       @media screen and (max-width: 800px) {
//         // background-image: url(../images/giftScreenMd.png);

//         height: 297px;
//       }
//       @media screen and (max-width: 500px) {
//         // background-image: url(../images/giftScreenSm.Png);

//         height: 297px;
//       }
//     }
//     &__red_box {
//       // background: #e4262b;
//       .ant-row {
//         height: 100%;
//       }
//       .camel_img,
//       .boy_img,
//       .bebo_belal_img {
//         max-width: 100%;
//       }
//       .question_box {
//         font-size: 30px;
//         color: #fff;
//         width: 100%;
//         // margin-top: 1.5rem;

//         @media screen and (max-width: 605px) {
//           font-size: 24px;
//         }
//       }
//     }
//     &__white_box {
//       border-bottom-left-radius: 50%;
//       border-bottom-right-radius: 50%;
//       background: url("../../assets/images/arch_bg.svg");
//       height: 50px;
//       background-position-x: center;
//       background-size: cover;
//       position: absolute;
//       left: 0;
//       bottom: 0;
//       width: 100%;
//     }
//   }
//   &__answers-box {
//     // margin-top: 3rem;
//     margin-bottom: 3rem;
//     @media screen and (max-width: 1025px) {
//       // margin-top: 5rem;
//     }
//     &--col {
//       display: flex;
//       justify-content: center;
//       width: 180px;
//       @media screen and (max-width: 576px) {
//         width: 320px;
//         display: flex;
//         justify-content: center;
//       }
//     }
//   }
//   &__answers_box {
//     &__container {
//       width: 250px;

//       justify-content: center;
//       align-items: center;
//       // margin: 0 4rem;
//       background: #e4262b;
//       border-radius: 15px;
//       color: #fff;
//       cursor: pointer;
//       font-size: 24px;
//       display: inline-block;
//       &.selected {
//         border: 2px solid #5f5f5f;
//         box-sizing: border-box;
//         -moz-box-sizing: border-box;
//         -webkit-box-sizing: border-box;
//       }
//       @media screen and (max-width: 574px) {
//         width: 320px;
//       }
//     }
//   }
//   &__submit_btn {
//     color: #fff;
//     position: relative;
//     margin-bottom: 2rem;
//     @media screen and (max-width: 777px) {
//       margin-bottom: 2rem;
//     }
//     &__text {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       font-size: 26px;
//     }
//   }
// }

.gifts-page {
  margin-top: -10px; //empty space between the nav and gift page section bug
  &__camelImg {
    overflow: hidden;
    height: 245.9px;
    transform: rotate(-3.1deg);
    position: absolute;
    top: 10%;
  }
  &__boyImg {
    overflow: hidden;
    height: 183px;
    transform: rotate(4deg);
    position: absolute;
    top: 37.5%;
  }
  &__beboAskBelalImg {
    margin-top: 2.2rem;
  }
  &__question-box {
    position: relative;
    overflow-wrap: break-word;

    &__redImage {
      position: absolute;
      left: 0;
      width: 100%;
      background-image: url(../images/giftScreenLg.png);
      opacity: 0.97;
      // top: 0;
      // height: 297px;
      // background-size: 100% 297px;
      top: $navbar-height;
      height: 100%;
      background-size: cover;
      background-position: bottom;
      @media screen and (max-width: 800px) {
        background-image: url(../images/giftScreenMd.png);
        height: calc(100% + 20px);
        // height: 297px;
      }
      @media screen and (max-width: 500px) {
        background-image: url(../images/giftScreenSm.Png);
        top: $navbar-height;
        height: 100%;
        // height: 297px;
      }
    }
    &__red_box {
      // background: #e4262b;
      .ant-row {
        height: 100%;
      }
      .camel_img,
      .boy_img,
      .bebo_belal_img {
        max-width: 100%;
      }
      .question_box {
        font-size: 30px;
        color: #fff;
        width: 100%;
        margin-top: 1.5rem;
        @media screen and (max-width: 605px) {
          font-size: 24px;
        }
      }
    }
    &__white_box {
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      background: url("../../assets/images/arch_bg.svg");
      height: 50px;
      background-position-x: center;
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
  &__answers-box {
    margin-top: 3rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 1025px) {
      margin-top: 5rem;
    }
    &--col {
      display: flex;
      justify-content: center;
      width: 180px;
      @media screen and (max-width: 576px) {
        width: 320px;
        display: flex;
        justify-content: center;
      }
    }
  }
  &__answers_box {
    &__container {
      width: 250px;

      justify-content: center;
      align-items: center;
      // margin: 0 4rem;
      background: #e4262b;
      border-radius: 15px;
      color: #fff;
      cursor: pointer;
      font-size: 24px;
      display: inline-block;
      &.selected {
        border: 2px solid #5f5f5f;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }
      @media screen and (max-width: 574px) {
        width: 320px;
      }
    }
  }
  &__submit_btn {
    color: #fff;
    position: relative;
    margin-bottom: 2rem;
    @media screen and (max-width: 777px) {
      margin-bottom: 2rem;
    }
    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
    }
  }
}
