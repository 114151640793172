.select-gender {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .single-centered-logo {
    margin-top: 140px;
  }
  &__title {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: $white;
    margin: 1rem auto;
  }
  &__antd-space {
    @include to-mobile-sm {
      gap: 20px !important;
    }
    @include to-mobile-xs {
      flex-direction: column;
      gap: 60px !important;
    }
  }
  &__radios {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 50px;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__radio {
    display: flex;
    justify-content: center;
    align-items: center;
    @include to-mobile-sm {
      gap: 15px !important;
    }
  }
  &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__figure {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 2rem;
    @include to-mobile-sm {
      width: 100px;
      height: 100px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__fig-caption {
    text-align: center;
    color: #231f20;
    color: $white;
    font-weight: 400;
    font-size: 35px;
  }
}
