.cannotChangePlanModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.ant-modal {
    width: 573px !important;
    z-index: 9999995 !important;
  }
  .ant-modal-content {
    border-radius: 25px;
    height: auto;
  }
  .ant-modal-body {
    padding: 70px 61px;
    font-size: 20px;
    line-height: 1.4;
    color: #ed2024;
    font-weight: 700;
    @include mobile-xs {
      padding: 40px 30px;
    }
    @include mobile-xxs {
      padding: 40px 20px;
    }
    @include to-tablet-xs {
      padding: 50px 40px;
    }
    @media only screen and (max-width: 430px) {
      padding: 30px 25px;
    }
  }
  .para {
    text-align: center;
  }
}
