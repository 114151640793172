.select-avatar {
  &__title {
    // font-size: 24px;
    // font-weight: bold;
    font-size: 30px;
    font-weight: 400;
    word-spacing: 3px;
    color: $white;
    margin-bottom: 1rem;
  }
  &__allAvatars {
    display: flex;
    flex-flow: row wrap;
    padding-inline: 5rem;
    &__container {
      // @include to-tablet-xs {
      //   row-gap: 120px;
      // }
    }
  }
  &__avatar {
    width: fit-content;
  }

  &__figure {
    width: 156px;
    // height: 156px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__image {
    width: 100%;
    height: 100%;
  }
}
.skins-tooltip {
  max-width: 600px;
  // left: 50% !important;
  // bottom: 50% !important;
  .ant-tooltip-inner {
    margin-top: -40px;
    width: fit-content;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: row wrap;
    background-color: blue;
  }
  .ant-tooltip-inner,
  .ant-tooltip-arrow-content::before {
    background: #e9eaec;
    border-radius: 8px;
    // background-color: green;
  }
  .select-avatar__skin {
    width: 100px;
    img {
      width: 100%;
    }
  }
}
// mediaQuery
@include to-tablet-sm {
  .select-avatar {
    &__image {
      width: 100%;
    }
    &__title {
      font-size: 18px;
    }
  }
}
