.logout-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.ant-modal {
    width: 50vw !important;
    z-index: 9999995 !important;
  }
  .ant-modal-content {
    border-radius: 25px;
    height: 623px;
  }
  &__header {
    h4 {
      color: $main-blue;
      font-size: 25px;
      font-weight: $bolder;
      margin-bottom: 2.25rem;
      text-align: center;
      margin-top: 72px;
    }
  }
  &__p {
    text-align: center;
    color: #707070;
    font-size: 1.25rem;
    font-weight: $bolder;
    // width: 24.25rem;
    margin-inline: auto;
    margin-bottom: 47px;
  }

  &__users {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  &__user {
    text-align: center;
    &:last-of-type {
      margin-inline-end: 0;
    }
    &:hover .logout-modal__name {
      color: $main-c;
    }
  }
  &__fig {
    width: 72px;
    height: 72px;
    margin-bottom: 1rem !important;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &__name {
    color: #c1b7b7;
    font-size: 1rem;
    font-weight: $bolder;
    transition: all 0.5s ease-in-out;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }
  &__btn {
    text-align: center;
    margin-top: 4.625rem;
  }
}

@include to-tablet-sm {
  .logout-modal {
    &.ant-modal {
      width: 90vw !important;
    }
  }
}
