.add-childs {
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__editImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__figure {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    .edit-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      z-index: 3;
    }
    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      top: 50%;
      left: 50%;
      width: 90%;
      height: 90%;
      transform: translate(-50%, -50%);
      background-color: rgba($main-blue, 0.2);
      z-index: 2;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      position: absolute;
    }
    &.show-edit {
      &::after,
      .edit-icon {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
  }
  &__form {
    width: 50%;
    margin: auto;
    @include to-tablet-sm {
      width: 60%;
    }
  }

  &__add-childs__form-item {
    width: 100%;
    .ant-select {
      width: 519px !important;
      height: 52px !important;
    }
  }
  &__form-item {
    .ant-input,
    .ant-input-number {
      border-color: $input-border-c;
      padding-block: 0.5rem !important;
      // color: $input-placeholder-c;
      &::placeholder {
        color: $input-placeholder-c !important;
      }

      height: 52px !important;
      width: 519px !important;
    }
    .ant-input-affix-wrapper,
    .ant-input-number-input-wrap {
      border-color: $input-border-c;
      align-items: center;
      padding-block: 0;
    }
    .ant-input-prefix {
      margin-right: 0;
      margin-inline-end: 0.75rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Works for Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .ant-input-number-input {
      height: 100% !important;
    }
    .ant-select-selection-search-input,
    .ant-input-number-input {
      &::placeholder {
        // color: $input-placeholder-c !important;
        color: $grey !important;
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
    .ant-select-selection-placeholder {
      // color: $input-placeholder-c !important;
      color: $grey !important;
    }
  }

  &__btn-cent {
    display: flex;
    justify-content: center;
  }
  &__form-item {
    .ant-input {
      border-color: $input-border-c;
      padding-block: 0.5rem !important;
      // color: $input-placeholder-c;
      color: $grey;
      &::placeholder {
        // color: $input-placeholder-c;
        color: $grey !important;
      }
    }
    .ant-input-affix-wrapper {
      border-color: $input-border-c;
      align-items: center;
      padding-block: 0;
    }
    .ant-input-prefix {
      margin-right: 0;
      margin-inline-end: 0.75rem;
    }
  }
  &__form-select {
    width: 100%;
  }
  &__inputError--avatar {
    width: 200px;
    color: red;
    height: 18px;
    text-align: center;
  }
  &__inputError--lang {
    width: 100%;
    color: red;
    height: 18px;
  }
  .ant-select-selection-placeholder {
    color: $input-placeholder-c !important;
  }
  .ant-select-selector {
    margin-bottom: 0.25rem !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    // height: 2.4375rem;
    height: 52px;
    width: 519px;
    align-items: center;
    margin-bottom: 2rem;
    border-color: $input-border-c !important;
  }
}

//media queries
@include to-mobile-sm {
  .add-childs {
    &__form {
      width: 70%;
    }
  }
}

.ant-select-add-child-language {
  .ant-select-arrow {
    left: 11px !important;
    display: inline-block !important;
    color: inherit !important;
    font-style: normal !important;
    line-height: 0 !important;
    text-transform: none !important;
    vertical-align: -0.125em !important;
    text-rendering: optimizelegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    position: absolute !important;
    top: 50% !important;
    display: flex !important;
    align-items: center !important;
    height: 12px !important;
    margin-top: -6px !important;
    color: rgba(0, 0, 0, 0.25) !important;
    font-size: 12px !important;
    line-height: 1 !important;
    text-align: center !important;
    pointer-events: none !important;
  }
}
