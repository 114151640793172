.subscribtion {
  &__content {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 25%;
    grid-template-areas: "list view view view";
  }
  &__list {
    // background-color: #dfe0e1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 100%vh;
    overflow-y: auto;
  }
  &__video-content {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    padding: 5px;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    // grid-template-areas: "vid details";
    column-gap: 5px;
    padding-block-end: 5px;
    border: solid 0.0625rem transparent;
    transition: $trans;
    margin-block-end: 1px;
    &.active {
      border: solid 0.0625rem $main-c;
    }
    &:hover {
      border: solid 0.0625rem $main-c;
    }
    // &__isFree--en {
    //   &::after {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     content: "Free";
    //     font-size: 10px;
    //     color: white;
    //     font-weight: 700;
    //     position: absolute;
    //     top: 5px;
    //     right: 5px;
    //     width: 32px;
    //     height: 32px;
    //     background-image: url("../../assets/images/freeEngIcon.svg");
    //     background-size: cover;
    //   }
    // }
    // &__isFree--ar {
    //   &::after {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     content: "مجاناً";
    //     font-size: 10px;
    //     color: white;
    //     font-weight: 700;
    //     position: absolute;
    //     top: 5px;
    //     left: 5px;
    //     width: 32px;
    //     height: 32px;
    //     background-image: url("../../assets/images/freeAricon.svg");
    //     background-size: cover;
    //   }
    // }
    &__isFree--notfree {
      background-color: #efefef;
    }
    // &::after {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   content: "Free";
    //   font-size: 10px;
    //   color: white;
    //   font-weight: 700;
    //   position: absolute;
    //   top: 5px;
    //   right: 5px;
    //   // transform: translate(-50%, -50%);
    //   width: 32px;
    //   height: 32px;
    //   background-image: url("../../assets/images/freeEngIcon.svg");
    //   background-size: cover;
    // }
  }

  &__figure {
    grid-area: vid;
    height: 98px;
    margin-bottom: 0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &--continuewatching {
      grid-area: vid;
      height: 100%;
      margin-bottom: 0;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
    }
    @media screen and (max-width: 979px) {
      // height: 80px;
    }

    .duration {
      position: absolute;
      right: 0.25rem;
      bottom: 0.25rem;
      font-size: 8px;
      padding: 0.125rem 0.25rem;
      color: white;
      background-color: #233a2f;
      border-radius: 0.25rem;
    }
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000080; /* Red semi-transparent background */
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1.5rem;
      /* You can add more styles like padding, border-radius, etc., as needed */
    }
  }
  &__image {
    // width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 4/3;
  }

  &__details {
    grid-area: details;
    padding-block: 1rem;
    @media screen and (max-width: 979px) {
      padding-block: 0rem;
    }
  }
  &__vid-name {
    @extend %video-name;
  }
  &__vid-details {
    @extend %video-details;
    color: $main-blue;
  }

  &__view {
    background-color: #fafafa;
    grid-area: view;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  &__logo {
    width: 200px;
    height: 100px;
  }
  &__logo-img {
    width: 100%;
  }
  &__title {
    text-align: center;
  }
  &__parag {
    font-size: 25px;
    font-weight: 500;
    color: #231f20 !important;
    padding-inline: 6.25rem;
  }
  &__btnn {
    width: 250px;
  }
}
//media query
@media screen and (max-width: 993px) {
  .subscribtion {
    &__content {
      display: flex;
      flex-direction: column;
    }
    &__view {
      width: 90%;
      margin-inline: auto;
      margin-block: 30px;
    }
    &__video-content {
      display: flex;
      justify-content: start;
    }
  }
}
@include to-tablet-sm {
  // .subscribtion {
  //   &__content {
  //     display: flex;
  //     flex-direction: column;
  //   }
  //   &__view {
  //     width: 90%;
  //     margin-inline: auto;
  //     margin-block: 30px;
  //   }
  //   &__video-content {
  //     display: flex;
  //     justify-content: start;
  //   }
  // }
}
