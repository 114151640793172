.pay {
  &__allPayments {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: baseline;
    margin-block-end: 4rem;
  }
  &__content {
    padding-block: 3rem;
  }
  &__logo {
    width: 100%;
    padding-inline-start: 1rem;
  }
  &__button-primary--cent {
    display: flex;
    justify-content: center;
  }
}
.ant-radio-wrapper {
  margin: 0;
}
.ant-radio {
  top: 0 !important;
}

@include to-mobile-sm {
  .pay {
    &__allPayments {
      grid-template-columns: 2fr 2fr;
      text-align: center;
    }
  }
}
