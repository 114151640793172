.verifyOldCode-modal {
  &_title {
    color: $main-blue;
    font-size: 20px;
    font-weight: $bolder;
  }
  &_message {
    font-size: 16px;
    font-weight: $bolder;
  }
  &_form {
    display: flex;
    width: 100%;

    &-input {
      width: 70%;
      border-radius: 4px;
      padding: 0px 5px;
      border: 1px solid $input-placeholder-c;
      color: $input-placeholder-c;
      &::placeholder {
        color: $input-placeholder-c;
      }

      &:focus-visible {
        outline: none;
      }
    }
    &-button {
      width: 70%;
      min-width: auto;
    }
  }
}
