.main-footer {
  // margin-top: 2rem;
  width: 100%;
  display: flex;
  align-self: flex-end;
  padding-top: 1.5rem;
  background: #181d25;
  color: white;
  text-align: center;
  padding-inline: 2rem;
  &.added-margin {
    // margin-top: 4.5625rem;
  }
  &__ul {
    display: flex;
    justify-content: center;

    .item {
      font-size: 1rem;
      margin-inline-end: 4.375rem;
      color: $white;
      &:last-of-type {
        margin-inline-end: 0;
      }
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.25rem;
    margin-bottom: 2.625rem;
    .item {
      margin-inline-end: 2rem;
      width: 3.25rem;
      &:last-of-type {
        margin-inline-end: 0;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__rights {
    color: $white;
  }
}

@include to-tablet-lg {
  .main-footer {
    &__ul {
      justify-content: space-around;
      .item {
        margin-inline-end: 0rem;
        &:last-of-type {
          margin-inline-end: 0rem;
        }
      }
    }
  }
}

@include to-tablet-sm {
  .main-footer {
    &__ul {
      flex-direction: column;
      .item {
        font-size: 1.2rem;

        margin-block-end: 0.5rem;
        &:last-of-type {
          margin-inline-end: 0rem;
        }
      }
    }
  }
}
