.privacyPolicy {
  padding-top: 6rem;
  padding-bottom: 3rem;
  color: $white;
  .title {
    // color: #356ab3;
    color: inherit;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;
  }

  p {
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
  }
}
