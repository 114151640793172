.PageNotFound {
  margin-bottom: 8rem;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }
  &__title {
    margin-top: 8.5rem;
    margin-bottom: 3.125rem;
    font-size: 2rem;
    font-weight: $bolder;
    align-items: center;
    color: $red;
  }
  &__fig {
    width: 18.25rem;
    margin-bottom: 2rem !important;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__btn {
    margin-top: 2rem;
    width: 200px;
    font-size: 1.125rem;
    padding-block: 12px;
    font-weight: $bolder;
    width: 19.625rem;
    background-color: $red;
    color: white;
    border-radius: 26px;
    text-align: center;
    &:hover {
      color: white !important;
    }
  }
}
@include to-mobile-sm {
}
