.AccountInProfile {
  &__content {
    width: 60%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__btn {
    width: 100%;
    padding-inline: 1rem;
    padding-block: 0.8rem;
    text-align: start;
    background-color: $accountProfile-pc;
    color: #356ab3;
    font-weight: 600;
    border-radius: 10px;
    border: none !important;
  }
  &__btn:hover {
    box-shadow: 0px 1px 3px rgb(182, 181, 181);
    // color: $white-blue;
    // font-weight: 900;
    // font-weight: 700;
  }
}
// media query
@include mobile-xs {
  .AccountInProfile {
    &__content {
      width: 90%;
    }
  }
}
@include mobile-sm {
  .AccountInProfile {
    &__content {
      width: 90%;
    }
  }
}
@include tablet-xs {
  .AccountInProfile {
    &__content {
      width: 80%;
    }
  }
}
@include tablet {
  .AccountInProfile {
    &__content {
      width: 70%;
    }
  }
}
