.watchers {
  &__logo {
    margin-top: 3.25rem;
    margin-bottom: 2.625rem;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    word-spacing: 0.1875rem;
    margin-bottom: 4.5625rem;
    color: $blue-title-c;
  }
  &__allAvatars {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 4.5rem;
  }
  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-block-end: 1.25rem;
  }
  &__figure {
    width: 10.3125rem;
    height: 10.3125rem;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0 !important;
  }
  &__avatar__image {
    width: 100%;
    object-fit: cover;
  }
  &__avatar__name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    // color: #c1b7b7;
    transition: all 0.5s ease-in-out;
    color: $accountProfile-pc !important;
    &:hover,
    &:focus {
      // color: $blue-title-c !important;
      color: $main-c !important;
    }
  }
  &__manage {
    font-weight: 400;
    font-size: 1.125rem;
    color: $white;
  }
  &__manage-text {
    font-size: 25px;
    font-weight: 700;
    // text-decoration: underline;
    border-bottom: 1px solid $white;
  }
}
// mediaQuery
@include to-mobile-sm {
  .watchers {
    &__avatar__image {
      width: 100%;
    }
    &__avatar__name {
      font-size: 1.125rem;
    }
    &__title {
      font-size: 1.125rem;
    }
  }
}

.single-centered {
  &-logo {
    margin-top: 3.25rem;
    margin-bottom: 2.625rem;
    text-align: center;
  }

  &-title {
    font-size: 25px;
    // color: $blue-title-c;
    color: $accountProfile-pc;
    font-weight: $bolder;
    text-align: center;
  }

  &-subtitle {
    margin-top: 1.375rem;
    margin-bottom: 3.25rem;
    font-size: 1.25rem;
    font-weight: $bolder;
    // color: $main-blue;
    color: $accountProfile-pc;
    text-align: center;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.camel-avatar {
  animation: rotate 2s linear infinite;
}
