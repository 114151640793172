.video-list-item {
  max-width: 100%;
  overflow: hidden;
  &__home-item-container {
    max-width: 100%;
    overflow: hidden;
    &:hover {
      transition: 0.3s ease;
      transform: scale(1.04);
      box-sizing: border-box;
    }
  }
  position: relative;
  // height: 232px; // FIXME: to be dynamic with scale based on screen ?
  // width: 286px;
  width: 217px;
  flex: 1;
  border-radius: 17.1px;
  object-fit: cover;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  @include to-tablet-lg {
    // width: 350px;
  }
  @media (min-width: 1366px) and (max-width: 1366px) {
    & {
      // width: 217px;
    }
  }
  @include to-tablet-sm {
    justify-self: center;

    // width: 400px;
  }
  @include to-mobile-s {
    justify-self: center;
    // width: 400px;
  }
  @include to-mobile-sm {
    justify-self: center;
    // width: 320px;
  }
  .video-lock-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .video-lock-overlay {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  &-has-free-video {
    // position: relative;
    &-en {
      &::before {
        content: "";
        background-image: url("../images/free\ badge.png");
      }
    }
    &-ar {
      &::before {
        content: "";
        // padding-right: 5px;
        background-image: url("../images/free\ badgeAr.png");
      }
    }
    &-en,
    &-ar {
      &::before {
        font-size: 10px;
        font-weight: 700;
        color: #ed2024;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 14px;
        position: absolute;
        width: 107px;
        height: 24.5px;
        top: 8%;
        right: 0;
        background-image: url("../images/freeEngIcon.svg"); /* Replace 'your-image-url.jpg' with the URL of your image */
      }
    }
  }
  & > div {
    // width: 100%;
    // height: 100%;
    background-size: cover;
  }

  &:hover {
    // transform: scale(1.14);
    .video-list-item__overlay {
      opacity: 1;
      bottom: 0;

      @include to-tablet-sm {
        bottom: 0px;
      }
    }
    .video-list-item__overlay--downloads {
      opacity: 1;
      bottom: 0;
    }
  }
  &__download-icon {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }

  &__overlay {
    // background-color: #82a2cd;
    // background-color: rgba($input-border-c, 0.6);
    background-color: rgba($input-border-c, 0.85);
    position: absolute;
    bottom: -20%;
    left: 0;
    right: 0;
    width: 100%;
    height: max-content;
    padding: 0.5rem 1rem;
    color: white;
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &__overlay--downloads {
    background-color: rgba($input-border-c, 0.6);
    position: absolute;
    bottom: -20%;
    left: 0;
    right: 0;
    width: 100%;
    height: max-content;
    padding: 0.5rem 1rem;
    color: white;
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &__title {
    width: 100%;
    font-size: 14px;
    // line-height: 11.49px;
    font-weight: 700;
    color: white;
    margin-bottom: 0.25rem;
    &--downloads {
      font-size: 1.2rem;
      font-weight: 700;
      color: white;
      margin-bottom: 0;
    }
  }

  &__details {
    display: flex;
    margin-top: 0.25rem;
  }

  &__cats {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    width: 100%;
    li {
      margin-inline-end: 0.5rem;
    }

    &__item {
      font-size: 14px;
      display: block;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        width: 0.45rem;
        height: 0.45rem;
        background-color: rgb(217, 202, 37);
        border-radius: 50%;
        margin-inline-end: 0.25rem;
      }
    }
  }
  &__controllers {
    display: flex;
    justify-content: space-between;
    .controller {
      img {
        width: 1rem;
        height: 1rem;
        margin-inline-end: 0.25rem;
      }
      font-size: 14px;
    }
  }
}
