.watch-now {
  .watch-now__video-show__wrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .watch-now__video-show {
      position: relative;
      height: 500px;
      max-height: 500px;
      .loading_screen {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4588235294);
      }
      .video-controller__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgb(0, 0, 0) 0%,
          rgba(0, 0, 0, 0.25) 50%,
          rgba(0, 0, 0, 0.2) 100%
        );
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;

        .controllers-settings {
          color: white;
        }

        &.show {
          visibility: visible;
        }
        &.hide {
          visibility: hidden;
        }
      }

      .video-controller__lower {
        height: 80px;
      }
    }
  }
}

.ant-popover {
  padding: 0 !important;
  color: white;

  .ant-popover-inner {
    background-color: #00000099;
    .ant-popover-title {
      color: white;
      border-bottom: 1px solid rgb(255 255 255 / 6%);
    }
    .ant-popover-inner-content {
      color: white;
      padding: 0;
      .setting-list-wrapper {
        &_item {
          transition: all 0.2s ease-in-out;
          padding: 5px;
          border-radius: 2px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.399);
          }
        }
      }
    }
  }
}
.ant-popover.volume-element {
  // top: 418px !important;
  // padding: 0 !important;
  .ant-popover-inner-content {
    padding: 5px;
    .ant-slider-vertical {
      height: 70px;
    }
  }
}
