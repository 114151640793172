.delete-account {
  &-modal {
    text-align: center;
  }
  &__header {
    margin-top: 3.25rem;
    h4 {
      font-size: 1.5rem;
      color: $main-blue;
      font-weight: $bolder;
      text-align: center;
    }
  }
  &__figure {
    width: 8rem;
    margin-block: 2rem 0rem !important;
    margin-inline: auto;
  }
  &__img {
    width: 100%;
    object-fit: cover;
  }
  &__name {
    font-size: 1.25rem;
    font-weight: $bolder;
    color: $main-blue;
    margin-bottom: 3.5rem;
  }
  &__btns {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 20px !important;
    .ant-space-item {
      flex: 1 0 auto;
    }
  }
}
.cancel_Btn {
  position: relative;
  border: none;
  color: #ed2024;
  flex: 1 0 auto;
  padding: 4px 15px;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 1500px;
    padding: 3px;
    background: linear-gradient(to bottom, #ffaaac, #ed2024);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
.delete-account-modal {
  .ant-modal-content {
    border-radius: 16px;
  }
}
