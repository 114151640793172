@font-face {
  font-family: "bahnschrift";
  src: url("./assets/fonts/bahnschrift/BAHNSCHRIFT\ 1.TTF");
}

@font-face {
  font-family: "Baloo Bhaijaan 2";
  src: url("./assets/fonts/baloobhaijaan2/baloobhaijaan2_regular.ttf");
}

#app {
  --orange-primary: #f58234;
  --orange-secondary: #cc5300;
  --blue-primary: #356ab3;
  --blue-secondary: #27a2d9;
  --blue-thirdy: #0370ff;
  --white: #ffffff;
  // font-family: "Cairo", sans-serif;
  // font-family: "Work Sans", sans-serif;
  // font-family: "Roboto", sans-serif;
  // font-family: "Comic Sans MS", "Baloo Bhaijaan 2", "Comic Sans", cursive;
  font-family: "bahnschrift", sans-serif;
  background-color: #1e2630;

  [lang="ar"] & {
    font-family: "Baloo Bhaijaan 2", serif;
  }
}
img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  // font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-family: "bahnschrift", sans-serif;
}
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.bord {
  border: solid 1px black;
}
.dark_blue {
  color: #356ab3;
}
.bg-white {
  background: #fff;
}
.txt-field-primary {
  padding-block: 1rem;
  border: solid 1px var(--blue-primary);
  border-radius: 5px;
  padding-inline-start: 3rem;
}
.txt-field-secondary {
  padding-block: 1rem;
  border: solid 1px var(--blue-primary);
  border-radius: 5px;
  padding-inline-start: 1.5rem;
  margin-block: 0.5rem;
}
.txt-field-primary.mob {
  margin-bottom: 1rem;
}

.button-primary:hover {
  background-color: var(--orange-secondary);
}
.button-primary[disabled],
.button-primary[disabled]:hover {
  opacity: 1;
  cursor: not-allowed;
  pointer-events: none;
  color: black; // Additional styles for the disabled state here
  // These will override the hover styles
  background-color: initial; // Reset background color for disabled state
  /* Add other styles specific to the disabled state */
}
.cursor-pointer {
  cursor: pointer;
}
.disable-pointer {
  pointer-events: none;
}
