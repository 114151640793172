.Customselect {
  width: 400px;
  margin: 0 auto 48px auto;
  position: relative;
  @include mobile-xs {
    width: 300px;
  }
  @include mobile-xxs {
    width: 250px;
  }
}
.Customselect .Customselect-btn {
  background-color: #ededed !important;
  padding: 22px 16px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  // color: #333;
  // border-bottom: 2px solid #356ab3;
  // color: #356ab3;
  border-bottom: 2px solid rgba($color: #000, $alpha: 0.12);
  color: $grey;
}

.Customselect-placeholder {
  color: $grey;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
}
.Customselect .Customselect-content {
  position: absolute;
  user-select: none;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  width: 100%;
  // color: #356ab3;
  // color: rgba($color: #000000, $alpha: 0.12);
  color: $grey;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

.Customselect .Customselect-content .Customselect-item {
  text-align: left;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.2s;
}
.Customselect .Customselect-content .Customselect-item:hover {
  background-color: #f4f4f4;
}
