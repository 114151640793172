.ContactUs {
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
  &__inputs {
    display: grid;
    grid-template-columns: 1fr;
  }
  &__xxx {
    border-radius: 5px;
  }
  &__input-container {
    @extend .InputPhone;
    // margin-block-end: 1.5rem;
    // .antd-country-phone-input {
    //   border: 1px solid $input-border-c;
    //   border-radius: 5px !important;
    //   .ant-select {
    //     color: $input-placeholder-c;
    //   }
    //   .fp.eg {
    //     border-radius: 50%;
    //     line-height: 1.5em;
    //   }
    // }
  }
  &__messageText {
    border: 1px solid $input-border-c;
    border-radius: 5px;
  }

  &__btn-send {
    width: 100%;
    text-align: center;
    margin-block-start: 2rem;
  }
}
// mediaquery
@include to-mobile-sm {
  .ContactUs {
    &__content {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
