.auth {
  &__title {
    margin-top: 2rem;
    margin-bottom: 1.75rem;
    font-weight: bold;
    font-size: 25px;
    text-align: start;
    padding-inline-start: 32px;
    // color: $blue-title-c;
    color: $accountProfile-pc;
  }

  &__form {
    margin-inline: auto;
    &--w50 {
      width: 519px;
      &-layout {
        display: flex;
        flex-direction: column;
        gap: 80px;
      }
      &-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      input {
        height: 52px !important;
      }
    }
    &__inputs-container {
      display: grid;
      grid-template-areas:
        "name email"
        "phone age"
        "password select-gender"
        "confirm-password select-gender";
      gap: 20px 80px;
      @media screen and (max-width: 27.5em) {
        grid-template-areas:
          "name"
          "email"
          "phone"
          "password"
          "confirm-password"
          "age"
          "select-gender";
        gap: 0.4rem;
      }

      &__input-name {
        grid-area: name;
      }
      &__input-email {
        grid-area: email;
      }
      &__input-phone {
        grid-area: phone;
        @extend .InputPhone;
      }
      &__input-age {
        grid-area: age;
      }
      &__input-password {
        grid-area: password;
      }
      &__input-confirm-password {
        grid-area: confirm-password;
      }
      &__input-select-gender {
        grid-area: select-gender;
      }

      input {
        height: 52px !important;
      }

      .ant-select-selector {
        height: 52px !important;
      }
    }

    .text-center {
      text-align: center;
    }
    &__submit {
      text-align: center;
    }
  }

  .otp__container {
    margin-bottom: 2rem;
  }

  &__form-item {
    .ant-input,
    .ant-input-number {
      border-color: $light-grey;
      padding-block: 0.5rem !important;
      color: $grey;
      &::placeholder {
        color: $grey !important;
      }
    }

    .ant-input-suffix {
      .ant-input-password-icon {
        svg,
        path {
          fill: $grey !important;
        }
      }
    }
    .ant-input-affix-wrapper,
    .ant-input-number-input-wrap {
      border-color: $light-grey;
      align-items: center;
      padding-block: 0;
    }
    .ant-input-prefix {
      margin-right: 0;
      margin-inline-end: 0.75rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Works for Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .ant-input-number-input {
      height: 100% !important;
    }
    .ant-select-selection-search-input,
    .ant-input-number-input {
      &::placeholder {
        // color: $input-placeholder-c !important;
        color: $grey !important;
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
    .ant-select-selection-placeholder {
      // color: $input-placeholder-c !important;
      color: $grey !important;
    }
  }

  &__form-select {
    width: 100%;
  }
  .ant-select {
    .ant-select-arrow {
      transform: translateY(-110%);
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 2.4375rem;
    align-items: center;
    margin-bottom: 2rem;
    // color: $input-placeholder-c !important;
    color: #000 !important;
    // border-color: $input-border-c !important;
  }
  .ant-statistic {
    .ant-statistic-content {
      .ant-statistic-content-value {
        color: $white;
      }
    }
  }
  .ant-select-selection-placeholder {
    // color: $input-placeholder-c;
    color: $grey !important;
  }
  &__forget-text {
    text-align: end;
    margin-bottom: 3.625rem;
    margin-top: -24px;
    a {
      // color: $link-new-c;
      // color: $accountProfile-pc;
      color: $red;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__sub-text {
    margin-top: 0.875rem;
    font-size: 14px;
    // font-weight: bold;
    span {
      color: $white;
      // color: $red;
    }
    a {
      color: $red;
      margin-inline: 0.2rem;
      // font-weight: $bolder;
      text-decoration: underline;
    }
  }

  .button-container {
    margin-bottom: 137px;
  }

  &__btn {
    &:disabled {
      background-color: #666;
      // color: #fff;
      cursor: not-allowed !important;
    }
    @include to-mobile-sm {
      width: 100%;
    }
    color: white;

    &.ant-btn:hover,
    &.ant-btn:focus {
      background: $main-grad !important;
      color: white;
    }
  }
  &__input-phone {
    @extend .InputPhone;
  }
  &__forget-pin-code-resend-code {
    // color: $link-new-c !important;
    color: $red !important;
    // border-bottom: 1px solid $link-new-c;
    border-bottom: 1px solid $red;
  }

  &__code-verification {
    display: flex;
    flex-direction: column;
    gap: 40px;

    margin-top: 78.33px;

    &__text-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      // margin-bottom: 67.67px;
      & div:nth-child(2) {
        margin-block-end: 0 !important;
      }
    }
  }
}
.resend-code-inActive {
  color: #a9a9a9 !important;
  border-bottom: 1px solid #a9a9a9;
}

.forget-pass {
  .auth__input-phone {
    width: 360px;
  }
  &__input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 4.5rem;
    .antd-country-phone-input {
      border: 1px solid $light-grey !important;
      width: 50%;
      padding-block: 0.5rem;
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: auto !important;
        border-color: transparent !important;
        margin-bottom: 0;
      }
      .ant-select .ant-select-arrow {
        transform: none;
      }
    }
  }
}

.forget-pin-code {
  .span {
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid $white;
    color: $white;
  }
}

@include to-mobile-sm {
  .auth {
    &__title {
      margin-inline-start: auto;
      margin-inline-end: auto;
    }

    &__form {
      &--w50 {
        width: 90%;
      }
    }

    // &__btn {
    //   width: 85%;
    // }
  }
}

// @include to-tablet-lg {
//   .auth {
//     &__form {
//       &--w50 {
//         width: 97%;
//       }
//     }
//   }
// }
