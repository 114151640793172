.watch-now {
  // margin-inline-end: 0.25rem;
  &__video-show {
    width: 100%;
    max-height: 500px;
    display: flex;
    position: relative;

    &:fullscreen {
      max-height: 100%;
    }
    &__wrapper {
      .timer {
        display: flex;
        padding-top: 1rem;
        justify-content: space-between;
        color: #fff;
        p {
          margin-bottom: 0 !important;
          font-size: 1rem;
          font-weight: $bolder;
        }
      }
    }

    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
    video {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      background: black;
    }

    .video-controller {
      overflow: hidden;
      // wrapper
      &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#333, 0.25);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.25) 50%,
          rgba(0, 0, 0, 0.2) 100%
        );
        display: flex;
        flex-direction: column;
        padding-top: 0.5rem;
      }
      &__loading_screen {
        background-color: rgba(0, 0, 0, 0.4);
      }
      &__upper {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__upper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-inline: 1rem;
          header h4 {
            color: white;
          }
        }

        &__center {
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex: 1;
          padding-inline: 1rem;
          img {
            transform: scale(1);
            transition: $trans;
            &:hover,
            &:active,
            &:focus {
              transform: scale(1.12);
            }
          }
        }
      }

      &__lower {
        // display: flex;
        // padding-block: 1.75rem;
        align-items: center;
        padding-inline: 2rem;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.75) 80%,
          rgba(0, 0, 0, 0.2) 100%
        );

        .controllers-settings {
          flex: 1;
          display: flex;
          justify-content: space-around;
          &__button {
            display: flex;
            align-items: center;
          }
          &__item {
            display: flex;
            align-items: center;
            position: relative;
            &__options {
              position: absolute;
              bottom: 100%;
              width: 155px;
              margin-bottom: 1rem;
              left: 0;
              color: white;
              left: 50%;
              transform: translateX(-50%);
              background: rgba(#333, 0.5);
              border-radius: 0.5rem;
              overflow: hidden;
              // show hide
              opacity: 0;
              visibility: hidden;
              transition: visibility 0s,
                opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
              // border: solid green 1px;
              &.show {
                visibility: visible;
                opacity: 1;
              }

              h6 {
                border-bottom: 1px solid rgba(white, 0.5);
                color: white;
                padding: 0.5rem;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: $bolder;
                display: flex;
                justify-content: start;
              }
              .item {
                padding-block: 0.25rem;
                padding-inline: 2rem;
                display: flex;
                justify-content: start;
                &:hover {
                  background-color: rgba(#333, 0.95);
                }

                &.active {
                  position: relative;
                  &:before {
                    content: "✓";
                    position: absolute;
                    top: 0;
                    left: 0.25rem;
                    font-size: 1rem;
                    transform: translate(50%, 50%);
                    line-height: 1;
                  }
                }
              }
            }

            img {
              margin-inline-end: 0.5rem;
            }
            p {
              margin-bottom: 0 !important  ;
              color: white;
              font-size: 0.75em;
            }
          }
        }
      }

      &__sound {
        position: relative;
        &__wrapper {
          min-height: 100px;
          position: absolute;
          bottom: 100%;
          margin-bottom: 1rem;
          transform: translateX(-50%);
          left: 12px;
          background-color: rgba(#333, 0.5);
          border-radius: 0.5rem;
          padding-inline: 0.5rem;
          padding-block: 1rem;
          &.right {
            right: 12px !important;
            left: auto !important;
          }
        }
        .sound {
          &-slider {
            height: 100px;
          }
        }
      }
    }
    .progress-slider {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: translateY(50%);
      .rc-slider-horizontal {
        &::after {
          position: absolute;
        }
      }
    }
  }
}

.controllers {
  position: "absolute";
  left: "50%";
  transform: "translateX(-50%)";
  height: "100%" !important;
  z-index: "5";
  width: 98% !important;
  margin: auto !important;
  opacity: 0;
  &:hover {
    opacity: 1 !important;

    ~ .bottom-controls {
      opacity: 1 !important;
    }
  }

  .controller-list {
    margin: "auto";
    max-width: 100%;
  }
}

.bottom-controls {
  opacity: 0;
  z-index: 5;
  max-width: 100% !important;
  &:hover {
    z-index: 6;
    opacity: 1;

    ~ .controllers {
      opacity: 1 !important;
    }
  }
}

.ui-video-seek-slider {
  // margin-left: 10px;
  // margin-right: 10px;
  margin-top: -15px;
}
.ui-video-seek-slider .track .main .connect {
  background: $main-c;
}
.ui-video-seek-slider .thumb.active .handler {
  background: white;
  border: 2px solid $main-c;
  transform: scale(1.25);
}
.ui-video-seek-slider .hover-time {
  background: #474747;
  font-weight: $bolder;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}
::cue {
  color: #fff;
  background: #000;
}
